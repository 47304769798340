import _taggedTemplateLiteral from "/Frontend/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20, _templateObject21, _templateObject22, _templateObject23, _templateObject24, _templateObject25, _templateObject26, _templateObject27, _templateObject28, _templateObject29, _templateObject30, _templateObject31, _templateObject32, _templateObject33;
import { css } from "@emotion/react";
import styled from "@emotion/styled";
var tf_blue = "#638dfc";
var tf_light_blue = "#bbceff";
var tf_superlight_blue = "#edf2ff";
export var H1 = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  font-family: \"Lexend\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 2.5rem;\n  line-height: 3.375rem;\n  letter-spacing: 0.2px;\n  color: black;\n  display: flex;\n  align-items: center;\n  ", "\n"])), function (props) { return props.breakWord ? css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n          word-wrap: break-word;\n          word-break: break-word;\n        "]))) : ""; });
export var H2 = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-family: \"Lexend\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 2.25rem;\n  line-height: 3rem;\n  letter-spacing: 0.2px;\n  color: black;\n  display: flex;\n  align-items: center;\n  ", "\n"])), function (props) { return props.breakWord ? css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n          word-wrap: break-word;\n          word-break: break-word;\n        "]))) : ""; });
export var H3 = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-family: \"Lexend\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 1.5rem;\n  line-height: 2rem;\n  letter-spacing: 0.2px;\n  color: black;\n  display: flex;\n  align-items: center;\n  ", "\n"])), function (props) { return props.breakWord ? css(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n          word-wrap: break-word;\n          word-break: break-word;\n        "]))) : ""; });
export var H4 = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  font-family: \"Lexend\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 1.125rem;\n  line-height: 1.5rem;\n  letter-spacing: 0.2px;\n  color: black;\n  display: flex;\n  align-items: center;\n  ", "\n"])), function (props) { return props.breakWord ? css(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n          word-wrap: break-word;\n          word-break: break-word;\n        "]))) : ""; });
export var RegularP = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  font-family: \"Lexend\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 0.875rem;\n  line-height: 1.125rem;\n  letter-spacing: 0.2px;\n  color: black;\n  display: flex;\n  align-items: center;\n  ", "\n"])), function (props) { return props.breakWord ? css(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n          word-wrap: break-word;\n          word-break: break-word;\n        "]))) : ""; });
export var MediumP = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  font-family: \"Lexend\";\n  font-style: normal;\n  font-weight: 500;\n  font-size: 0.875rem;\n  line-height: 1.125rem;\n  letter-spacing: 0.2px;\n  color: black;\n  display: flex;\n  align-items: center;\n  ", "\n"])), function (props) { return props.breakWord ? css(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n          word-wrap: break-word;\n          word-break: break-word;\n        "]))) : ""; });
export var BoldP = styled.div(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  font-family: \"Lexend\";\n  font-style: normal;\n  font-weight: bold;\n  font-size: 0.875rem;\n  line-height: 1.125rem;\n  letter-spacing: 0.2px;\n  color: black;\n  display: flex;\n  text-align: start;\n  align-items: center;\n  ", "\n"])), function (props) { return props.breakWord ? css(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n          word-wrap: break-word;\n          word-break: break-word;\n        "]))) : ""; });
export var SmallP = styled.div(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  font-family: \"Lexend\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 0.625rem;\n  line-height: 1.125rem;\n  letter-spacing: 0.2px;\n  color: black;\n  display: flex;\n  align-items: center;\n  ", "\n"])), function (props) { return props.breakWord ? css(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n          word-wrap: break-word;\n          word-break: break-word;\n        "]))) : ""; });
export var InfoP = styled.div(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n  font-family: \"Lexend\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 0.625rem;\n  line-height: 1.125rem;\n  letter-spacing: 0.2px;\n  color: #afafaf;\n  text-align: start;\n  ", "\n"])), function (props) { return props.breakWord ? css(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n          word-wrap: break-word;\n          word-break: break-word;\n        "]))) : ""; });
export var ClickableText = styled.button(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["\n  cursor: pointer;\n  color: ", ";\n  transition: all 0.4s;\n  font-weight: bold;\n  * {\n    color: ", " !important;\n    transition: all 0.4s;\n  }\n  &:hover {\n    * {\n      color: ", " !important;\n    }\n    color: ", ";\n  }\n  ", "\n"])), tf_blue, tf_blue, tf_light_blue, tf_light_blue, function (props) { return props.marginLeft ? css(_templateObject20 || (_templateObject20 = _taggedTemplateLiteral(["\n          margin-left: 8px;\n        "]))) : ""; });
export var Row8Gap = styled.div(_templateObject21 || (_templateObject21 = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  gap: 8px;\n  align-items: center;\n"])));
export var ColumnNoGap = styled.div(_templateObject22 || (_templateObject22 = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n"])));
export var ButtonWrapper = styled.div(_templateObject23 || (_templateObject23 = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  justify-content: flex-end;\n"])));
export var NormalRow = styled("div")(function (props) { return "\n  width: 100%;\n  display: flex;\n  align-items: center;\n  gap: 1.5rem;\n  ".concat(props.fullHeight ? "height: 100%;" : "", "\n  ").concat(props.gap ? "gap: ".concat(props.gap, ";") : "", "\n"); });
export var EqualSizedColumn = styled("div")(function (_ref) { var justifyContent = _ref.justifyContent; return "\n    display: flex;\n    flex: 1;\n    ".concat(justifyContent ? "justify-content: ".concat(justifyContent, ";") : "", "\n"); });
export var RowSpaceBetween = styled.div(_templateObject24 || (_templateObject24 = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"])));
export var NormalColumn = styled("div")(function (props) { return "\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  ".concat(props.fullHeight ? "height: 100%;" : "", "\n  ").concat(props.gap ? "gap: ".concat(props.gap, ";") : "", "\n"); });
export var CenteredColumn = styled("div")(function (props) { return "\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  ".concat(props.fullHeight ? "height: 100%;" : "", "\n  ").concat(props.gap ? "gap: ".concat(props.gap, ";") : "", "\n"); });
export var VerticalDivider = styled.div(_templateObject25 || (_templateObject25 = _taggedTemplateLiteral(["\n  height: 100%;\n  background-color: ", ";\n  border: 1px solid ", ";\n"])), tf_superlight_blue, tf_superlight_blue);
export var HorizontalDivider = styled("div")(function (props) { return "\n  width: 100%;\n  background-color: ".concat(tf_superlight_blue, ";\n  border: 1px solid ").concat(tf_superlight_blue, ";\n  ").concat(props.hideOnPrint ? "@media print {display: none;}" : "", "\n"); });
export var Table = styled.div(_templateObject26 || (_templateObject26 = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 0.75rem;\n"])));
export var TR = styled.div(_templateObject27 || (_templateObject27 = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  gap: 1.5rem;\n  padding-bottom: 0.75rem;\n  align-items: center;\n  border-bottom: 1px solid ", ";\n  justify-content: space-between;\n"])), tf_superlight_blue);
export var TH = styled.div(_templateObject28 || (_templateObject28 = _taggedTemplateLiteral(["\n  width: fit-content;\n  ", "\n"])), function (props) { return props.fullWidth ? css(_templateObject29 || (_templateObject29 = _taggedTemplateLiteral(["\n          width: 100%;\n        "]))) : ""; });
export var TD = styled.div(_templateObject30 || (_templateObject30 = _taggedTemplateLiteral(["\n  width: fit-content;\n  ", "\n"])), function (props) { return props.fullWidth ? css(_templateObject31 || (_templateObject31 = _taggedTemplateLiteral(["\n          width: 100%;\n        "]))) : ""; });
export var SmallStatusBadge = styled.div(_templateObject32 || (_templateObject32 = _taggedTemplateLiteral(["\n  border-radius: 0.375rem;\n  padding: 0.25rem;\n  width: max-content;\n  * {\n    color: white !important;\n    font-weight: bold !important;\n  }\n"])));
export var Div = styled.div(_templateObject33 || (_templateObject33 = _taggedTemplateLiteral(["\n  width: max-content;\n  height: max-content;\n"])));
