import _taggedTemplateLiteral from "/Frontend/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17;
import { css } from "@emotion/react";
import styled from "@emotion/styled";
export var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  transition: all 0.4s;\n  padding: 1.5rem;\n  background: #ffffff;\n  box-shadow: 0px 0px 32px rgba(223, 225, 226, 0.5);\n  border-radius: 2.25rem;\n  display: flex;\n  flex-direction: column;\n  gap: 0.75rem;\n  ", "\n  ", "\n"])), function (props) { return props.fullWidth ? css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n          width: 100%;\n        "]))) : css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n          width: 100%;\n          max-width: 23.4375rem;\n        "]))); }, function (props) { return props.fullHeight ? css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n          height: 100%;\n        "]))) : ""; });
export var ContainerForm = styled.form(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  transition: all 0.4s;\n  padding: 1.5rem;\n  background: #ffffff;\n  box-shadow: 0px 0px 32px rgba(223, 225, 226, 0.5);\n  border-radius: 2.25rem;\n  display: flex;\n  flex-direction: column;\n  gap: 0.75rem;\n  ", "\n  ", "\n"])), function (props) { return props.fullWidth ? css(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n          width: 100%;\n        "]))) : css(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n          width: 100%;\n          max-width: 23.4375rem;\n        "]))); }, function (props) { return props.fullHeight ? css(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n          height: 100%;\n        "]))) : ""; });
export var ContainerNoPadding = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  padding-bottom: 1.5rem;\n  transition: all 0.4s;\n  background: #ffffff;\n  box-shadow: 0px 0px 32px rgba(223, 225, 226, 0.5);\n  border-radius: 2.25rem;\n  display: flex;\n  flex-direction: column;\n  gap: 0.75rem;\n  ", "\n  ", "\n"])), function (props) { return props.fullWidth ? css(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n          width: 100%;\n        "]))) : css(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n          width: 100%;\n          max-width: 375px;\n        "]))); }, function (props) { return props.fullHeight ? css(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n          height: 100%;\n        "]))) : ""; });
export var OutlinedContainer = styled.div(function (props) { return "\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  padding: 0.75rem;\n  gap: 1.5rem;\n  background: #ffffff;\n  border: 1px solid #bdbdbd;\n  border-radius: 1.5rem;\n  ".concat(props.fullWidth ? "width: 100%;" : "", "\n"); });
export var ContainerBody = styled.div(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  padding: 0px 0px;\n  transition: all 0.4s;\n  height: 100%;\n  overflow-y: auto;\n  display: flex;\n  flex-direction: column;\n  gap: 0.75rem;\n"])));
export var ContainerTitleWrapper = styled.div(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"])));
export var ContainerTitleWrapperPadding = styled.div(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 1.5rem 1.5rem 0px 1.5rem;\n"])));
export var ContainerTitle = styled.div(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n"])));
export var ContainerTitleButtons = styled.div(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n"])));
export var SmallContainer = styled("div")(function (props) { return "\n  background: #ffffff;\n  box-shadow: 0px 0px 1.5rem rgba(213, 224, 233, 0.4);\n  padding: 0.75rem;\n  border-radius: 1rem;\n  width: 100%;\n  ".concat(props.stroke ? "border: 0.5px solid #EDF2FF;" : "", "\n"); }); // Where are my components? Where did the go? I can't find them!
