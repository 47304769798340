import _objectSpread from "/Frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { ValidateText } from "../../../../Services/Validation/ValidateText";
import { SurveyFrequencyList, SurveyTypesList } from "../../../../Models/Surveys";
export var validateNewSurveyObject = function validateNewSurveyObject(survey, snackbar, lang, errMsg, setOpenSettings, setOpenRespondents) {
    var tempSurvey = _objectSpread({}, survey);
    if (!tempSurvey || typeof tempSurvey !== "object") {
        snackbar(errMsg.invalidObject, "error");
        return false;
    }
    if (!tempSurvey.surveyName || tempSurvey.surveyName === "" || !ValidateText(tempSurvey.surveyName, 100)) {
        snackbar(lang.errors.invalidSurveyName, "error");
        setOpenSettings(true);
        return false;
    }
    if (!tempSurvey.surveyType || !SurveyTypesList.includes(tempSurvey.surveyType)) {
        snackbar(lang.errors.invalidSurveyType, "error");
        setOpenSettings(true);
        return false;
    }
    if (!tempSurvey.surveyFrequency || !SurveyFrequencyList.includes(tempSurvey.surveyFrequency)) {
        snackbar(lang.errors.invalidFrequency, "error");
        setOpenSettings(true);
        return false;
    } // if (
    //   !tempSurvey.finishDate ||
    //   dayjs(tempSurvey.finishDate).format("YYYY-MM-DD") === "Invalid Date"
    // ) {
    //   snackbar(lang.errors.invalidDate, "error");
    //   setOpenSettings(true);
    //   return false;
    // }
    // if (dayjs(tempSurvey.finishDate).isBefore(dayjs(new Date()))) {
    //   snackbar(lang.errors.finishDateHasToBeInFuture, "error");
    //   return false;
    // }
    if (!tempSurvey.users || !Array.isArray(tempSurvey.users) || tempSurvey.users.length === 0) {
        setOpenRespondents(true);
        snackbar(lang.errors.youNeedToHaveAtLeastOneRespondent, "error");
        return false;
    }
    if (!tempSurvey.numberOfQuestion || tempSurvey.numberOfQuestion < 1 || tempSurvey.numberOfQuestion > tempSurvey.addQuestionToNewForms.length) {
        setOpenSettings(true);
        snackbar(lang.errors.invalidNumberOfQuestions, "error");
        return false;
    }
    if (!tempSurvey.addQuestionToNewForms || !Array.isArray(tempSurvey.addQuestionToNewForms) || tempSurvey.addQuestionToNewForms.length === 0) {
        snackbar(lang.errors.invalidQuestions, "error");
        return false;
    }
    if (!tempSurvey.organizationId || tempSurvey.organizationId === "-2") {
        snackbar(lang.errors.invalidOrganizationId, "error");
        return false;
    }
    if (tempSurvey.addQuestionToNewForms && tempSurvey.addQuestionToNewForms.length > 0) {
        var tempQuestions = tempSurvey.addQuestionToNewForms;
        for (var i = 0; i < tempQuestions.length; i++) {
            var tempQuestion = tempQuestions[i];
            if (!tempQuestion.question || !ValidateText(tempQuestion.question, 500)) {
                snackbar(lang.errors.missingParamsInTag, "error");
                return false;
            }
            if (!tempQuestion.questionType) {
                snackbar(lang.errors.missingParamsInTag, "error");
                return false;
            }
            if ((!tempQuestion.tagName || !ValidateText(tempQuestion.tagName, 50)) && tempSurvey.surveyType === "shuffled") {
                snackbar(lang.errors.oneOfTheTagsHasAnInvalidName, "error");
                return false;
            }
            if (tempQuestion.questionType !== "OPEN_TEXT" && !tempQuestion.answersJSON) {
                snackbar(lang.errors.missingParamsInTag, "error");
                return false;
            }
            if (tempQuestion.questionType !== "OPEN_TEXT" && tempQuestion.questionType !== "ONE_CHOICE") {
                var keys = Object.keys(tempQuestions[i].answersJSON);
                if (!keys || keys.length < 1 || !Array.isArray(keys)) {
                    snackbar(lang.errors.missingParamsInTag, "error");
                    return false;
                }
                for (var j = 0; j < keys.length; j++) {
                    var key = keys[j];
                    if (!tempQuestion.answersJSON[key] || tempQuestion.answersJSON[key] === "" || !ValidateText(tempQuestion.answersJSON[key], 200)) {
                        snackbar(lang.errors.missingParamsInTag, "error");
                        return false;
                    }
                }
            }
            else if (tempQuestion.questionType === "ONE_CHOICE") {
                var answers = tempQuestion.answersJSON.answersArray;
                if (!answers || answers.length < 2 || !Array.isArray(answers)) {
                    snackbar(lang.errors.missingParamsInTag, "error");
                    return false;
                }
                for (var _j = 0; _j < answers.length; _j++) {
                    if (!answers[_j] || answers[_j] === "" || !ValidateText(answers[_j], 300)) {
                        snackbar(lang.errors.missingParamsInTag, "error");
                        return false;
                    }
                }
            }
        }
    }
    return true;
};
export var validateEditSurveyObject = function validateEditSurveyObject(survey, snackbar, lang, errMsg, setOpenSettings, setOpenRespondents) {
    var tempSurvey = _objectSpread({}, survey);
    if (!tempSurvey || typeof tempSurvey !== "object") {
        snackbar(errMsg.invalidObject, "error");
        return false;
    }
    if (!tempSurvey.surveyType || !SurveyTypesList.includes(tempSurvey.surveyType)) {
        snackbar(lang.errors.invalidSurveyType, "error");
        setOpenSettings(true);
        return false;
    }
    if (!tempSurvey.surveyFrequency || !SurveyFrequencyList.includes(tempSurvey.surveyFrequency)) {
        snackbar(lang.errors.invalidFrequency, "error");
        setOpenSettings(true);
        return false;
    } // if (
    //   !tempSurvey.finishDate ||
    //   dayjs(tempSurvey.finishDate).format("YYYY-MM-DD") === "Invalid Date"
    // ) {
    //   snackbar(lang.errors.invalidDate, "error");
    //   setOpenSettings(true);
    //   return false;
    // }
    // if (dayjs(tempSurvey.finishDate).isBefore(dayjs(new Date()))) {
    //   snackbar(lang.errors.finishDateHasToBeInFuture, "error");
    //   return false;
    // }
    if (!tempSurvey.users || !Array.isArray(tempSurvey.users) || tempSurvey.users.length === 0) {
        setOpenRespondents(true);
        snackbar(lang.errors.youNeedToHaveAtLeastOneRespondent, "error");
        return false;
    }
    if (!tempSurvey.numberOfQuestion || tempSurvey.numberOfQuestion < 1 || tempSurvey.numberOfQuestion > (tempSurvey.formQuestions ? tempSurvey.formQuestions.length : 0) + (tempSurvey.addedQuestion ? tempSurvey.addedQuestion.length : 0)) {
        setOpenSettings(true);
        snackbar(lang.errors.invalidNumberOfQuestions, "error");
        return false;
    }
    if ((tempSurvey.formQuestions ? tempSurvey.formQuestions.length : 0) + (tempSurvey.addedQuestion ? tempSurvey.addedQuestion.length : 0) < 1) {
        snackbar(lang.errors.invalidQuestions, "error");
        return false;
    }
    if (tempSurvey.addedQuestion && tempSurvey.addedQuestion.length > 0) {
        var tempQuestions = tempSurvey.addedQuestion;
        for (var i = 0; i < tempQuestions.length; i++) {
            var tempQuestion = tempQuestions[i];
            if (!tempQuestion.question || !ValidateText(tempQuestion.question, 500)) {
                snackbar(lang.errors.missingParamsInTag, "error");
                return false;
            }
            if (!tempQuestion.questionType) {
                snackbar(lang.errors.missingParamsInTag, "error");
                return false;
            }
            if ((!tempQuestion.tagName || !ValidateText(tempQuestion.tagName, 50)) && tempSurvey.surveyType === "shuffled") {
                snackbar(lang.errors.oneOfTheTagsHasAnInvalidName, "error");
                return false;
            }
            if (tempQuestion.questionType !== "OPEN_TEXT" && !tempQuestion.answersJSON) {
                snackbar(lang.errors.missingParamsInTag, "error");
                return false;
            }
            if (tempQuestion.questionType !== "OPEN_TEXT" && tempQuestion.questionType !== "ONE_CHOICE") {
                var tempAnswersJson = JSON.parse(tempQuestions[i].answersJSON);
                var keys = Object.keys(tempAnswersJson);
                if (!keys || keys.length < 1 || !Array.isArray(keys)) {
                    snackbar(lang.errors.missingParamsInTag, "error");
                    return false;
                }
                for (var j = 0; j < keys.length; j++) {
                    var key = keys[j];
                    if (!tempAnswersJson[key] || tempAnswersJson[key] === "" || !ValidateText(tempAnswersJson[key], 200)) {
                        snackbar(lang.errors.missingParamsInTag, "error");
                        return false;
                    }
                }
            }
            else if (tempQuestion.questionType === "ONE_CHOICE") {
                var answers = JSON.parse(tempQuestion.answersJSON).answersArray;
                if (!answers || answers.length < 2 || !Array.isArray(answers)) {
                    snackbar(lang.errors.missingParamsInTag, "error");
                    return false;
                }
                for (var _j2 = 0; _j2 < answers.length; _j2++) {
                    if (!answers[_j2] || answers[_j2] === "" || !ValidateText(answers[_j2], 300)) {
                        snackbar(lang.errors.missingParamsInTag, "error");
                        return false;
                    }
                }
            }
        }
    }
    return true;
};
